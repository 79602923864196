<template>
  <div>
    <Phone :therapistInfo="therapistInfo" :editable.sync="editable" />
    <About
      :profile="profile"
      :profileAr="profileAr"
      :editable.sync="editable"
    />
    <CategoryTitle
      :rules="required"
      :therapistInfo="therapistInfo"
      :editable.sync="editable"
    />
    <Experience
      :rules="required"
      :therapistInfo="therapistInfo"
      :editable.sync="editable"
    />
    <Capacity
      :capacityRules="capacityRules"
      :therapistInfo="therapistInfo"
      :editable.sync="editable"
    />
    <Education :therapistInfo="therapistInfo" :editable.sync="editable" />
    <LanguageSpeak :therapistInfo="therapistInfo" :editable.sync="editable" />
    <License :therapistInfo="therapistInfo" :editable.sync="editable" />
    <TrainingCertification
      :therapistInfo="therapistInfo"
      :editable.sync="editable"
    />
    <Disorders
      :therapistDisorders="therapistDisorders"
      :editable.sync="editable"
      @update:therapistDisorders="
        (val, id) => $emit('update:therapistDisorders', val, id)
      "
    />
  </div>
</template>

<script>
import About from "./About";
import Phone from "./Phone";
import Capacity from "./Capacity";
import Education from "./Education";
import LanguageSpeak from "./LanguageSpeak";
import License from "./License";
import Disorders from "./Disorders";
import TrainingCertification from "./TrainingCertification";
import CategoryTitle from "./CategoryTitle";
import Experience from "./Experience";

export default {
  name: "therapistInfo",
  components: {
    Education,
    LanguageSpeak,
    License,
    TrainingCertification,
    About,
    Phone,
    Capacity,
    Disorders,
    CategoryTitle,
    Experience,
  },
  props: {
    editable: {
      required: true,
    },

    profile: {
      required: true,
    },

    profileAr: {
      required: true,
    },

    therapistInfo: {
      required: true,
    },
    therapistDisorders: {
      required: true,
    },
  },
  // data: () => {
  //   return {
  //     capacityRules: [
  //       (v) => !!v || 'Capacity is required',
  //       (v) => (v && v > -1) || 'Min 0',
  //       (v) => (v && v <= 50) || 'Max 50',
  //     ],

  //     required: [(v) => !!v || 'This field is required'],
  //   }
  // },

  computed: {
    capacityRules() {
      return [
        (v) => !!v || this.$t("Capacity is required"),
        (v) => (v && v > -1) || this.$t("Minimum 0"),
        (v) => (v && v <= 50) || this.$t("Maximum 50"),
      ];
    },
    required() {
      return [(v) => !!v || this.$t("This field is required")];
    },
  },
};
</script>
