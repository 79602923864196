<template>
  <v-row>
    <v-col cols="12">
      <v-text-field
        v-model="therapistInfo.capacity"
        :rules="capacityRules"
        type="number"
        :label="$t('Capacity')"
        min="0"
        max="50"
        :readonly="!editable"
      >
      </v-text-field>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Capacity",
  props: {
    editable: {
      required: true,
    },
    therapistInfo: {
      required: true,
    },
    capacityRules: {
      required: true,
    },
  },
};
</script>
