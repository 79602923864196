var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-7"},[_c('v-row',[_c('v-col',{staticClass:"mt-5",attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0 bg-transparent",attrs:{"headers":_vm.headers,"items":_vm.userHistory,"items-per-page":5,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(_vm.getType(item)))+" ")]}},{key:"item.purchaseDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(_vm.getPurchaseDate(item)))+" ")]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(_vm.getEndDate(item)))+" ")]}},{key:"item.renewalDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(_vm.getRenewalDate(item)))+" ")]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(_vm.getValue(item)))+" "),_c('br'),_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t("5% VAT is applied to all purchases at checkout.")))])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }