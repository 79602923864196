<template>
  <v-row justify="center">
    <v-dialog
      :value="addLiveSession"
      @input="closeAddLiveSession(false)"
      scrollable
      width="700"
    >
      <v-card class="rounded-lg">
        <v-btn
          @click="closeAddLiveSession(false)"
          elevation="2"
          icon
          class="close-icon pa-0"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title class="pt-10">
          <h4 class="text-center headline font-weight-medium w-100">
            {{ $t("Add a single session or a bundle and save up to 11%") }}
          </h4>
        </v-card-title>
        <v-card-text class="pa-8">
          <v-row class="align-center justify-center">
            <v-col md="10" class="mx-auto">
              <v-radio-group v-model="selectedOption">
                <template v-for="(opt, index) in optionsData">
                  <v-radio
                    :key="index"
                    v-if="opt.show"
                    class="font-weight-medium"
                    :value="opt"
                    model-value="name"
                  >
                    <template v-slot:label>
                      <div
                        :style="
                          $vuetify.rtl
                            ? 'text-align: right !important;'
                            : 'text-align: left;'
                        "
                      >
                        <strong
                          class="text-primary font-weight-bold font-size-14"
                          >{{ $t(opt.label) }}</strong
                        ><br />
                        <span class="body-2">{{ $t(opt.option) }}</span>
                      </div>
                    </template>
                  </v-radio>
                </template>
              </v-radio-group>
            </v-col>
          </v-row>
          <p class="text-center w-100 mt-8 mb-0">
            {{ $t("You will be charged") }}:
          </p>
          <p class="secondary--text text-center w-100">
            <b v-if="selectedOption && selectedOption.price"
              >{{ selectedOption.price }} AED</b
            >
          </p>
          <!-- TODO: Need to be under if client have no subscription or subscription ended-->
          <v-sheet color="info" class="pa-3 rounded-15px">
            <p class="text-center white--text mb-0">
              <i>{{
                $t(
                  "Your session credits will not expire when your subscription ends. They will remain available for you to use at a later time. Cancel your subscription anytime"
                )
              }}</i>
            </p>
          </v-sheet>
        </v-card-text>
        <v-card-actions class="pb-6">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="mt-5 mt-md-0 ms-md-7 mb-2 px-8"
            :block="
              $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? true : false
            "
            :loading="loading"
            :disabled="loading || !selectedOption"
            @click="checkoutAction"
          >
            {{ $t("Proceed to checkout") }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import UserMixin from "@/mixins/UserMixin";
export default {
  name: "AddLiveSession",
  mixins: [UserMixin],

  props: {
    addLiveSession: {
      required: true,
    },
    loading: {
      required: false,
      default: false,
    },
  },

  data: () => {
    return {
      optionsData: [
        {
          show: true,
          label: "1 Session",
          option: "AED 450",
          price: "450",
          name: "1 Session",
          actionValue: "LIVE_SESSION_1",
          type: "session",
        },
        {
          show: true,
          label: "2 Sessions",
          option: "AED 400/session (Save AED 100)",
          price: "800",
          name: "2 Sessions",
          actionValue: "LIVE_SESSION_2",
          type: "session",
        },
      ],

      selectedOption: "",
      //   numberOfLiveSessions: 1,
    };
  },

  computed: {
    ...mapGetters("subscription", ["subscriptions"]),
  },

  methods: {
    ...mapActions("subscription", [
      "createLiveSession",
      "subscribeAction",
      "getSubscriptions",
    ]),

    // formattedLabel(labelText) {
    //   const labelParts = labelText.split(':');
    //   const boldPart = `<span style="font-weight: bold;">${labelParts[0]}</span>`;
    //   return `${boldPart}:${labelParts[1]}`;
    // },

    checkoutAction() {
      if (this.selectedOption.type === "session") {
        this.createLiveSessionAction(this.selectedOption.actionValue);
      } else if (this.selectedOption.type === "subscription") {
        this.onSubscribeAction(
          this.selectedOption.actionValue,
          this.selectedOption.packageName
        );
      }
    },

    async createLiveSessionAction(liveSessionName) {
      this.$emit("update:loading", true);
      let liveSessionActionRes = await this.createLiveSession({
        name: liveSessionName,
      });
      this.$emit("update:loading", false);
      window.location.replace(liveSessionActionRes.url);
    },

    async onSubscribeAction(priceId, packageName) {
      let therapistId = this.myTherapistId;

      let data = {
        therapistId: therapistId,
        packageName: packageName,
        priceId: priceId,
      };

      this.$emit("update:loading", true);
      let subscribeActionRes = await this.subscribeAction(data);
      this.$emit("update:loading", false);

      window.location.replace(subscribeActionRes.url);
    },

    closeAddLiveSession(val) {
      this.$emit("update:addLiveSession", val);
    },
  },

  async mounted() {
    this.selectedOption =
      this.optionsData && this.optionsData.length ? this.optionsData[0] : [];

    if (!this.subscription?.stripeSubscription) {
      if (!this.subscriptions?.length) {
        await this.getSubscriptions();
      }

      this.optionsData.push(
        ...[
          {
            show: !this.subscription?.stripeSubscription,
            label: "Subscribe for 1 month - 4 sessions",
            option: "AED 363/session (Save AED 350)",
            price: "1,450",
            packageName: "month_1",
            name: "Subscribe for 1 month",
            actionValue: this.subscriptions["month_1"][0].id,
            type: "subscription",
          },
          {
            show: !this.subscription?.stripeSubscription,
            label: "Subscribe for 2 months - 8 sessions",
            option: "AED 338/session (Save AED 900)",
            price: "2,700",
            packageName: "month_2",
            name: "Subscribe for 2 month",
            actionValue: this.subscriptions["month_2"][0].id,
            type: "subscription",
          },
        ]
      );
    }
  },
};
</script>
