<template>
  <v-row>
    <v-col cols="12">
      <h4>{{ $t("Language") }}</h4>
    </v-col>
    <v-col cols="12" class="py-0">
      <v-row class="ma-0">
        <v-col class="pa-0">
          <!-- <v-list tag="ul" class="pa-0" dense> -->
          <span
            class="pa-0 font-size-14"
            :readonly="!editable"
            v-for="(lang, i) in therapistInfo.languageSpeak"
            :key="lang"
          >
            {{ $t(lang)
            }}<template v-if="i < therapistInfo.languageSpeak.length - 1">{{
              ", "
            }}</template>
          </span>
          <!-- </v-list> -->
        </v-col>
        <!-- <v-col cols="auto" class="pa-0"> -->
        <!-- <v-icon v-if="editable" class="me-5" @click="editItem">mdi-pencil</v-icon> -->
        <!-- <v-icon v-if="editable" class="me-5" @click="deleteConfirmation(i)">mdi-delete</v-icon> -->
        <!-- </v-col> -->
      </v-row>
    </v-col>
    <v-col class="pt-0 pb-12">
      <!-- <v-btn text color="primary" v-if="editable" @click="editItem" class="font-weight-bold" :ripple="false">
                <v-icon color="primary" left class="me-5">mdi-plus-box-multiple</v-icon>
                
            </v-btn> -->
      <v-btn
        color="primary"
        text
        class="mt-5"
        v-if="editable"
        :ripple="false"
        @click="editItem"
      >
        <v-icon small left class="me-1">mdi-plus-box-multiple</v-icon>
        {{ $t("Add Language") }}
      </v-btn>
    </v-col>

    <v-dialog v-model="languageDialog" width="500">
      <v-card class="rounded-lg">
        <v-btn
          @click="languageDialog = false"
          elevation="2"
          icon
          class="close-icon pa-0"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-card-text class="pt-10">
          <v-row>
            <v-col cols="12">
              <!-- <v-text-field v-model="itemForEdit.name" label="Language"></v-text-field> -->
              <v-combobox
                v-model="therapistInfo.languageSpeak"
                :items="languagesDefault"
                :label="$t('Language')"
                multiple
                outlined
                dense
              >
              </v-combobox>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="py-5 justify-center">
          <v-btn color="primary" outlined @click="close">
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn color="primary" @click="save">
            {{ $t("Save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <v-dialog transition="dialog-bottom-transition" max-width="600" v-model="deleteConfirmationDialog">
            <v-card class="pt-10 pb-5">
                <v-card-text>
                    <h3 class="text-center">Are you sure you want to delete?</h3>
                </v-card-text>
                <v-card-actions class="justify-center">
                    <v-btn color="primary" outlined @click="deleteConfirmationDialog = false">No</v-btn>
                    <v-btn color="primary" @click="deleteItem(deleteItemIndex)">Yes</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog> -->
  </v-row>
</template>

<script>
// function defaultItem() {
//     return []
// }

export default {
  name: "Language",
  props: {
    editable: {
      required: true,
    },
    therapistInfo: {
      required: true,
    },
  },
  data: () => ({
    languageDialog: false,
    languagesDefault: [
      "English",
      "Arabic",
      "French",
      "Spanish",
      "Italian",
      "Greek",
      "Hindi",
      "Urdu",
      "Malayalam",
      "Russian",
    ],
    // deleteConfirmationDialog: false,
    // deleteItemIndex: null,
    // itemForEdit: defaultItem(),
  }),

  methods: {
    editItem() {
      // this.itemForEdit = [];
      // this.itemForEdit._isNew = isNew;
      // this.itemForEdit._ref = item;

      this.languageDialog = true;
    },

    // deleteConfirmation(index) {
    // this.deleteConfirmationDialog = true
    // this.deleteItemIndex = index
    // },

    // deleteItem(index) {
    //     this.therapistInfo.languageSpeak.splice(index, 1);
    //     this.deleteConfirmationDialog = false
    // },

    // addItem() {
    //     const item = defaultItem();
    //     this.editItem(item, true)
    // },

    close() {
      this.languageDialog = false;
    },

    save() {
      // this.therapistInfo.languageSpeak.push(this.itemForEdit._ref);

      this.close();
    },

    // getLanguageSpeak(lang) {
    //     return this.$t(lang)
    // }
  },

  mounted() {
    if (!Array.isArray(this.therapistInfo.languageSpeak)) {
      this.therapistInfo.languageSpeak = [];
    }
  },
};
</script>
