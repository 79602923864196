<template>
  <v-row>
    <v-col cols="12">
      <h4>{{ $t("License") }}</h4>
    </v-col>
    <v-col cols="12">
      <v-text-field
        :readonly="!editable"
        :label="$t('Licensing Authority')"
        v-model="therapistInfo.license.licensingAuthority"
      >
      </v-text-field>
    </v-col>
    <v-col cols="12">
      <v-text-field
        :readonly="!editable"
        :label="$t('Issue Date')"
        v-model="therapistInfo.license.issueDate"
      >
      </v-text-field>
    </v-col>
    <v-col cols="12">
      <v-text-field
        :readonly="!editable"
        :label="$t('Expiration Date')"
        v-model="therapistInfo.license.expirationDate"
      >
      </v-text-field>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "License",
  props: {
    editable: {
      required: true,
    },
    therapistInfo: {
      required: true,
    },
  },
  data: () => {
    return {
      license: {
        licensingAuthority: "",
        issueDate: "",
        expirationDate: "",
      },
    };
  },
};
</script>
