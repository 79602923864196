<template>
  <v-container>
    <v-row class="pb-2">
      <v-col cols="12" sm="auto">
        <h2 class="headline font-weight-medium pb-2">{{ $t("Account") }}</h2>
      </v-col>
      <v-col cols="12" sm="auto" class="ms-sm-auto">
        <v-btn
          color="secondary"
          class="mt-5 mt-sm-2"
          small
          :block="
            $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? true : false
          "
          :loading="loading"
          :disabled="loading"
          @click="addLiveSession = true"
        >
          <v-icon left>mdi-plus-circle-outline</v-icon>
          {{ $t("Buy additional Live Session credits") }}
        </v-btn>

        <AddLiveSession
          :addLiveSession.sync="addLiveSession"
          :loading.sync="loading"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">
        <h4 class="font-weight-medium pb-2">{{ $t("MyTherapist") }}:</h4>

        <v-card
          elevation="1"
          outlined
          rounded="lg"
          class="py-5 px-4 text-center mt-2"
          :class="
            $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
              ? ''
              : 'fill-height'
          "
        >
          <UserAvatar :user="myTherapist" :size="150" />
          <p class="mt-5 headline primary--text">
            {{ getUserName(myTherapist) }}
          </p>
          <p class="font-size-14 mt-1 mb-0 opacity-50">
            {{ therapistCategoryTitle(myTherapist) }}
          </p>
          <p class="font-size-14 mt-1 mb-6 opacity-50">
            {{ therapistExperienceYears(myTherapist) }}
            {{ $t("years in practice") }}
          </p>
        </v-card>
      </v-col>
      <v-col cols="12" md="8" class="ps-md-6">
        <h4 class="font-weight-medium pb-2">
          {{ $t("MyPlan") }}:
          <strong>{{ $t(currentPlan) }}</strong>
        </h4>
        <v-card
          :loading="loading"
          elevation="1"
          outlined
          rounded="lg"
          class="pb-4 px-5 mt-2 fill-height d-flex flex-column"
        >
<!--          <subscription-type/>-->
<!--          <v-list>-->
<!--            <v-list-item class="px-0">-->
<!--              {{ $t("Subscription Type") }}-->
<!--              <br />-->
<!--              <strong class="ms-3 dark&#45;&#45;text">-->
<!--                {{ $t(currentPlan) }}-->
<!--              </strong>-->
<!--            </v-list-item>-->
<!--            <v-list-item class="px-0">-->
<!--              {{ $t("Subscription Duration") }}:-->
<!--              <br />-->
<!--              <strong class="ms-3 dark&#45;&#45;text"-->
<!--                >{{ $t(currentPlanDuration) }} - {{ renewalDate }}</strong-->
<!--              >-->
<!--            </v-list-item>-->
<!--            <v-list-item class="px-0">-->
<!--              {{ $t("Renewal date") }}:-->
<!--              <br />-->
<!--              <strong class="ms-3 dark&#45;&#45;text">-->
<!--                <template v-if="cancelAtPeriodEnd">-</template>-->
<!--                <template v-else>-->
<!--                  {{ renewalDate }} {{ renewalPrice }}-->
<!--                </template>-->
<!--              </strong>-->
<!--            </v-list-item>-->
<!--          </v-list>-->

          <v-list>
            <v-subheader class="font-weight-bold body-1 ps-0">{{ $t('Personal') }}</v-subheader>
            <v-list-item class="ps-0">
              <v-list-item-content>
                <v-list-item-title>{{ $t("Subscription Duration") }}: <span class="font-weight-bold">  <strong class="ms-3 dark--text"
                >{{ $t(currentPlan) }}</strong
                ></span></v-list-item-title>
                <v-list-item-title class="px-0 mt-3">
                  {{ $t("Renewal date") }}:
                  <strong class="ms-3 dark--text">
                    <template v-if="cancelAtPeriodEnd">-</template>
                    <template v-else>
                      {{ renewalDate }} {{ renewalPrice }}
                    </template>
                  </strong>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <!-- <v-list>
            <v-list-item-title class="font-weight-bold mt-4 mb-3">
              {{ $t("Live Session Credits:") }}
            </v-list-item-title>
            <v-list-item class="px-0">
              {{ $t("Live Session(s)") }} :

              <span
                class="ml-1 body-1 font-weight-medium secondary--text title"
              >
                {{ liveSessionCount }}
              </span>
            </v-list-item>
          </v-list> -->

          <p class="dark--text">
            {{ $t("Live Session Credits:") }}
            <strong>
              {{ liveSessionCount }}
            </strong>
          </p>
          <p class="dark--text">
            {{ $t("Free session credit") }}:
            <strong>
              {{ liveFreeSessionCount }}
            </strong>
          </p>

          <div class="d-flex flex-wrap mt-auto">
            <v-btn
              color="secondary"
              small
              :block="
                $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? true : false
              "
              outlined
              @click="toggleHistory"
              >{{ historyText }}
            </v-btn>
            <v-btn
              v-if="!isMeCompanyUser"
              color="secondary"
              class="mt-5 mt-md-0 mb-2 ms-auto me-3"
              small
              :block="
                $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? true : false
              "
              outlined
              :disabled="!!subscription.stripeSubscription"
              @click="toggleChangePlan"
            >
              <v-icon left>mdi-swap-horizontal</v-icon>
              {{ $t("Change Subscription") }}
            </v-btn>

            <v-dialog
              v-if="!isMeCompanyUser"
              v-model="endSubscriptionDialog"
              width="700"
            >
              <v-card class="rounded-lg" v-if="!isMeCompanyUser">
                <v-btn
                  @click="endSubscriptionDialog = false"
                  elevation="2"
                  icon
                  class="close-icon pa-0"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>

                <v-card-text class="pa-8">
                  <h4 class="heading text-center mb-5 font-weight-bold">
                    {{ $t("Your Subscription") }} "{{ currentPlan }}"
                    {{ $t("is active until") }} {{ renewalDate }}
                  </h4>
                  <p class="text-center">
                    {{
                      $t("Are you sure you want to end subscription renewal?")
                    }}
                  </p>
                </v-card-text>
                <v-card-actions class="pb-6">
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    class="mt-5 mt-md-0 mb-2 px-8"
                    :loading="loading"
                    :disabled="loading || cancelAtPeriodEnd"
                    @click="cancelSubscriptionAction"
                  >
                    Yes
                  </v-btn>
                  <v-btn
                    class="mt-5 mt-md-0 ms-7 mb-2 px-8"
                    @click="endSubscriptionDialog = false"
                    color="primary"
                    outlined
                  >
                    No
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-btn
              v-if="!isMeCompanyUser"
              color="danger opacity-50"
              class="mt-5 mt-md-0 mb-2"
              small
              outlined
              :disabled="loading || cancelAtPeriodEnd"
              :block="
                $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? true : false
              "
              @click="endSubscriptionDialog = true"
            >
              <v-icon left small>mdi-close-circle</v-icon>
              {{ $t("End Subscription Renewal") }}
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import SubscriptionType from './SubscriptionType.vue'

const moment = require("moment");

import AddLiveSession from "@/components/Billing/AddLiveSession";
import UserAvatar from "@/components/UserAvatar";
import UserMixin from "@/mixins/UserMixin";
import { mapActions } from "vuex";
import TherapistMixin from "@/mixins/TherapistMixin";

export default {
  name: "MainBilling",
  components: {
    // SubscriptionType,
    UserAvatar,
    AddLiveSession,
  },

  mixins: [UserMixin, TherapistMixin],
  props: {
    history: {
      required: true,
    },
    changePlan: {
      required: true,
    },
  },

  data: () => {
    return {
      addLiveSession: false,
      loading: false,
      endSubscriptionDialog: false,
    };
  },

  computed: {
    historyText() {
      if (!this.history) {
        return "Show History";
      }
      return "Hide History";
    },
    liveSessionCount() {
      return (
        (this.me?.metadata?.numberLiveSessionsHave || 0) +
        (this.me?.metadata?.numberCorporateLiveSessionsHave || 0)
      );
    },
    liveFreeSessionCount() {
      return this.me?.metadata?.numberFreeLiveSessionsHave || "0";
    },

    currentPlan() {
      return this.subscription?.stripeSubscription?.plan?.metadata?.type || "";
    },

    currentPlanDuration() {
      return this.subscription?.stripeSubscription?.plan?.metadata?.name || "";
    },

    renewalDate() {
      if (!this.subscription?.stripeSubscription?.current_period_end) return "";
      return (
        moment(
          +this.subscription?.stripeSubscription?.current_period_end * 1000
        ).format("LL") || ""
      );
    },

    renewalPrice() {
      let amount = this.subscription?.stripeSubscription?.plan?.amount / 100;
      let currency =
        this.subscription?.stripeSubscription?.plan?.currency?.toUpperCase();
      if (!amount) {
        return "-";
      }
      return `(${amount} ${currency})`;
    },

    cancelAtPeriodEnd() {
      return (
        this.subscription?.stripeSubscription?.cancel_at_period_end ?? true
      );
    },
  },

  methods: {
    ...mapActions("subscription", ["cancelSubscriptionEndOfPeriod"]),

    toggleChangePlan() {
      this.$emit("update:changePlan", !this.changePlan);
    },

    toggleHistory() {
      this.$emit("update:history", !this.history);
    },

    async cancelSubscriptionAction() {
      this.loading = true;
      await this.cancelSubscriptionEndOfPeriod();
      await this.getMe();
      this.endSubscriptionDialog = false;
      this.loading = false;
    },
  },
};
</script>

<style lang="scss"></style>
