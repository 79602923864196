<template>
  <v-card elevation="1" :loading="loading">
    <template slot="progress">
      <v-progress-linear indeterminate></v-progress-linear>
    </template>

    <v-toolbar class="primary" height="150" elevation="0">
      <UserAvatar :user="me" :size="150" />
    </v-toolbar>

    <v-layout justify-center class="mt-15">
      <v-btn
        class="upload-image mt-10 mb-5"
        outlined
        color="primary"
        :loading="loading"
        :disabled="loading"
        @click="openFileInput"
      >
        {{ $t("ChangePicture") }}
      </v-btn>

      <input
        ref="fileInputRef"
        type="file"
        :name="uploadFieldName"
        :disabled="loading"
        accept="image/*"
        class="input-file"
        @change="filesChange"
      />
    </v-layout>

    <v-card-text class="pb-15 text-center">
      {{ $t("PicRequirements") }}
    </v-card-text>
  </v-card>
</template>

<script>
import UserAvatar from "@/components/UserAvatar";
import UserMixin from "@/mixins/UserMixin";

export default {
  name: "ProfileAvatar",
  components: { UserAvatar },
  mixins: [UserMixin],
  data: () => {
    return {
      loading: false,
      // formValid: true,
      uploadFieldName: "image",
      uploadedFile: null,
      // fileBase64String: null,
      fileBuffer: null,
    };
  },

  methods: {
    // async createAction() {
    //   // if (this.$refs.createFromTemplateForm.validate()) {
    //   try {
    //     this.loading = true;
    //     let pageData = {};
    //     pageData = new FormData();
    //     pageData.append('image', this.uploadedFile, this.uploadedFile.name);
    //     console.log(pageData)
    //   } catch (err) {
    //     console.error(err);
    //   } finally {
    //     this.loading = false;
    //   }
    //
    // },

    openFileInput() {
      this.$refs.fileInputRef.click();
    },

    uploadChunk(id, index, chunks, cb) {
      this.$socket.emit(
        "upload-chunk",
        {
          id: id,
          data: chunks[index],
        },
        () => {
          if (index < chunks.length - 1) {
            this.uploadChunk(id, index + 1, chunks, cb);
          } else {
            this.$socket.emit(
              "upload-end",
              {
                id: id,
              },
              (data) => {
                if (typeof cb === "function") {
                  cb(data);
                }
              }
            );
          }
        }
      );
    },

    startUpload() {
      const chunkSize = 500000; // 500kb
      const buffLen = Math.ceil(this.fileBuffer.byteLength / chunkSize);
      let splitChunks = [];
      for (let i = 0; i < buffLen; i++) {
        splitChunks.push(
          this.fileBuffer.slice(i * chunkSize, chunkSize * (i + 1))
        );
      }

      // TODO: error handling
      this.$socket.emit(
        "request-file-upload",
        {
          name: this.uploadedFile.name,
          type: this.uploadedFile.type,
        },
        (data) => {
          let id = data.id;
          if (id) {
            this.uploadChunk(id, 0, splitChunks, (res) => {
              if (res.path) {
                this.me.profile.avatar = res.path;
              }
              this.loading = false;
            });
          }
        }
      );
    },

    async filesChange() {
      this.uploadedFile = this.$refs.fileInputRef.files[0];
      if (this.uploadedFile) {
        // this.fileBuffer = await this.toBuffer(this.uploadedFile);
        // this.fileBuffer = await this.toBase64(this.uploadedFile);
        this.loading = true;
        // console.log("fileBuffer: ", this.fileBuffer);

        let image = document.createElement("IMG");
        image.src = await this.toBase64(this.uploadedFile);
        image.onload = () => {
          let canvas = document.createElement("CANVAS"),
            max_size = 800, // TODO : pull max size from a site config
            width = image.width,
            height = image.height;
          if (width > height) {
            if (width > max_size) {
              height *= max_size / width;
              width = max_size;
            }
          } else {
            if (height > max_size) {
              width *= max_size / height;
              height = max_size;
            }
          }
          canvas.width = width;
          canvas.height = height;
          canvas.getContext("2d").drawImage(image, 0, 0, width, height);
          canvas.toBlob(async (blob) => {
            this.fileBuffer = await blob.arrayBuffer();
            this.startUpload();
          });
        };

        // console.log("this.uploadedFile: ", this.uploadedFile);

        // console.log("fileBuffer: ", this.fileBuffer);

        // this.$socket.emit('upload-avatar', {
        //   name: this.uploadedFile.name,
        //   type: this.uploadedFile.type,
        //   data: this.fileBuffer
        // }, (res => {
        //   if (res.path) {
        //     this.me.profile.avatar = res.path;
        //   }
        //   console.log("res.path: ", res);
        //   this.loading = false;
        // }))
      }

      // this.clearFileInput()
      // this.fileBase64String = await this.toBase64(this.uploadedFile);
    },

    async clearFileInput() {
      this.uploadedFile = null;
      // this.fileBase64String = null;
      this.fileBuffer = null;
      this.$refs.fileInputRef.value = "";
    },

    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    toBuffer(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
  },

  mounted() {
    window.profileAvatar = this;
  },
};
</script>

<style scoped lang="scss">
.upload-image .v-btn__content {
  position: unset;
}

.v-avatar::v-deep {
  position: absolute;
  bottom: 0;
  transform: translate(-50%, 50%);
  left: 50%;
}

.input-file {
  display: none;
}
</style>
