<template>
  <v-row>
    <v-col cols="12">
      <v-text-field
        v-model="therapistInfo.phoneNumber"
        type="tel"
        :label="$t('Phone Number')"
        :readonly="!editable"
      >
      </v-text-field>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Phone",
  props: {
    editable: {
      required: true,
    },
    therapistInfo: {
      required: true,
    },
  },
};
</script>
