<template>
  <v-row>
    <v-col cols="12">
      <v-text-field
        v-model="therapistInfo.experienceYears"
        :rules="rules"
        type="number"
        :readonly="!editable"
        :label="$t('years in practice')"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Experience",
  props: {
    editable: {
      required: true,
    },
    therapistInfo: {
      required: true,
    },
    rules: {
      required: true,
    },
  },
};
</script>
