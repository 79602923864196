<template>
  <v-row v-if="disordersByCategories">
    <v-col cols="12" class="mt-4">
      <h4>{{ $t("Speciality") }}</h4>
    </v-col>

    <template v-for="category in Object.keys(disordersByCategories)">
      <v-col cols="12" class="mt-3 pb-0" :key="category">
        <span>{{ category }}</span>
      </v-col>

      <v-col
        class="py-0"
        v-for="dis in disordersByCategories[category]"
        :key="dis._id"
        sm="6"
        cols="12"
      >
        <v-checkbox
          hide-details
          :label="$t(dis.name)"
          :readonly="!editable"
          :class="[{ 'pointer-events-none': !editable }]"
          :input-value="therapistDisorders.includes(dis._id)"
          @change="addDeleteDisorder($event, dis._id)"
        />
      </v-col>
    </template>

    <!--        <v-col-->
    <!--            class="py-0"-->
    <!--            v-for="dis in disorders"-->
    <!--            :key="dis._id"-->
    <!--            sm="6"-->
    <!--            cols="12"-->
    <!--        >-->
    <!--            <v-checkbox-->
    <!--                hide-details-->
    <!--                :label="$t(dis.name)"-->
    <!--                :readonly="!editable"-->
    <!--                :class="[{ 'pointer-events-none': !editable }]"-->
    <!--                :input-value="therapistDisorders.includes(dis._id)"-->
    <!--                @change="addDeleteDisorder($event, dis._id)"-->
    <!--            />-->
    <!--        </v-col>-->
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import UserMixin from "@/mixins/UserMixin";

export default {
  name: "Disorders",
  mixins: [UserMixin],
  props: {
    editable: {
      required: true,
    },
    therapistDisorders: {
      required: true,
    },
  },
  computed: {
    ...mapGetters("disorder", ["disorders"]),

    disordersByCategories() {
      let disorders = this.disorders.reduce((a, r) => {
        if (!a[r.category]) {
          a[r.category] = [];
        }
        a[r.category].push(r);

        return a;
      }, {});

      console.log("disorders: ", disorders);
      return disorders;
    },
  },
  methods: {
    ...mapActions("disorder", {
      disorderGetAll: "getAll",
    }),

    addDeleteDisorder(val, id) {
      this.$emit("update:therapistDisorders", val, id);
      // if (val) {
      //     if (!this.disorder.default.includes(id)) {
      //         this.disorder.default.push(id)
      //     }
      // } else {
      //     let index = this.disorder.default.findIndex(e => e === id)
      //
      //     if (index != -1) {
      //         this.disorder.default.splice(index, 1);
      //     }
      // }
    },
  },
  mounted() {
    this.awaitPromiseCallback({
      key: "getMe",
      cb: () => {
        this.disorderGetAll().catch((err) =>
          console.error("Error on Disorder get", err)
        );
      },
    });
  },
};
</script>
