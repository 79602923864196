<template>
  <v-container class="mt-7">
    <v-row>
      <v-col cols="12" class="mt-5">
        <v-data-table
          :headers="headers"
          :items="userHistory"
          :items-per-page="5"
          :loading="loading"
          class="elevation-0 bg-transparent"
        >
          <template v-slot:item.type="{ item }">
            {{ $t(getType(item)) }}
          </template>

          <template v-slot:item.purchaseDate="{ item }">
            {{ $t(getPurchaseDate(item)) }}
          </template>

          <template v-slot:item.endDate="{ item }">
            {{ $t(getEndDate(item)) }}
          </template>

          <template v-slot:item.renewalDate="{ item }">
            {{ $t(getRenewalDate(item)) }}
          </template>

          <template v-slot:item.value="{ item }">
            {{ $t(getValue(item)) }} <br/>
            <span class="caption">{{ $t("5% VAT is applied to all purchases at checkout.") }}</span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";

export default {
  name: "History",
  data() {
    return {
      loading: false,
      userHistory: [],
      headers: [
        {
          text: "Type",
          align: "start",
          sortable: false,
          value: "type",
        },
        { text: "Purchase Date", value: "purchaseDate", sortable: false },
        { text: "End Date", value: "endDate", sortable: false },
        { text: "Renewal Date", value: "renewalDate", sortable: false },
        { text: "Value", value: "value", sortable: false },
      ],
    };
  },
  methods: {
    ...mapActions("report", ["getUserHistory"]),

    getType(item) {
      if (item.subscription) {
        return (
          item?.subscription?.items?.data?.[0]?.price?.metadata?.type || ""
        );
      } else if (item.liveSession) {
        return item?.liveSession?.price?.nickname + " Live Session";
      }

      return "-";
    },

    getPurchaseDate(item) {
      if (item.subscription) {
        return moment(item.subscription.start_date * 1000).format("L LT");
      } else if (item.liveSession) {
        return moment(item.createdAt).format("L LT");
      }

      return "-";
    },

    getEndDate(item) {
      if (item.subscription) {
        if (item.subscription.canceled_at)
          return moment(item.subscription.canceled_at * 1000).format("L LT");
        if (item.subscription.cancel_at_period_end)
          return moment(item.subscription.current_period_end * 1000).format(
            "L LT"
          );
      }

      return "-";
    },

    getRenewalDate(item) {
      if (item.subscription) {
        if (!item.subscription.cancel_at_period_end)
          return moment(item.subscription.current_period_end * 1000).format(
            "L LT"
          );
      }

      return "-";
    },

    getValue(item) {
      let amount = "",
        currency = "";

      if (item.subscription) {
        amount = item?.subscription?.items?.data?.[0]?.price?.unit_amount;
        currency = item?.subscription?.items?.data?.[0]?.price?.currency;
      } else if (item.liveSession) {
        amount = item?.liveSession?.amount_total;
        currency = item?.liveSession?.currency;
      }
      if (!amount) return "";

      return (amount / 100).toFixed(2) + " " + currency;
    },
  },

  async mounted() {
    try {
      this.loading = true;
      this.userHistory = await this.getUserHistory();
      this.userHistory = this.userHistory.filter(
        (h) => h?.subscription || h?.liveSession
      );
      // console.log("this.userHistory: ", this.userHistory);
    } catch (e) {
      this.addNotification({
        message: e.response.data.message,
        icon: "mdi-alert",
        timeout: 10000,
      });
    } finally {
      this.loading = false;
    }
  },
};
</script>
