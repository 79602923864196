<template>
  <v-row>
    <v-col cols="12">
      <h4>{{ $t("Education") }}</h4>
    </v-col>
    <v-col cols="12" :key="i" v-for="(edu, i) in therapistInfo.education">
      <v-row class="ma-0">
        <v-col class="px-0">
          <v-list tag="ul" class="pa-0 bg-transparent" dense>
            <v-list-item tag="li" class="px-0" :readonly="!editable">
              <v-list-item-content>
                <v-list-item-title
                  ><b>{{ $t("Degree Name") }}</b></v-list-item-title
                >
                <v-list-item-subtitle>{{
                  edu.degreeName || "-"
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item tag="li" class="px-0" :readonly="!editable">
              <v-list-item-content>
                <v-list-item-title
                  ><b
                    >{{ $t("Degree Name") }} {{ $t("Arabic") }}</b
                  ></v-list-item-title
                >
                <v-list-item-subtitle>{{
                  edu.degreeNameAr || "-"
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item tag="li" class="px-0" :readonly="!editable">
              <v-list-item-content>
                <v-list-item-title
                  ><b>{{ $t("Graduation Year") }}</b></v-list-item-title
                >
                <v-list-item-subtitle>{{
                  edu.graduationYear || "-"
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item tag="li" class="px-0" :readonly="!editable">
              <v-list-item-content>
                <v-list-item-title
                  ><b>{{ $t("Institution") }}</b></v-list-item-title
                >
                <v-list-item-subtitle>{{
                  edu.institution || "-"
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item tag="li" class="px-0" :readonly="!editable">
              <v-list-item-content>
                <v-list-item-title
                  ><b
                    >{{ $t("Institution") }} {{ $t("Arabic") }}</b
                  ></v-list-item-title
                >
                <v-list-item-subtitle>{{
                  edu.institutionAr || "-"
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="auto">
          <v-icon v-if="editable" class="me-5" @click="editItem(edu)"
            >mdi-pencil</v-icon
          >
          <v-icon v-if="editable" class="me-5" @click="deleteConfirmation(i)"
            >mdi-delete</v-icon
          >
        </v-col>
      </v-row>
    </v-col>
    <v-col class="pt-0 pb-12">
      <!-- <v-btn text color="primary" @click="addItem" class="px-0 font-weight-bold" v-if="editable" :ripple="false">
                <v-icon color="primary" class="me-5">mdi-plus-box-multiple</v-icon>
                Add Education
            </v-btn> -->

      <v-btn
        color="primary"
        text
        class="mt-5"
        v-if="editable"
        :ripple="false"
        @click="addItem"
      >
        <v-icon small left class="me-1">mdi-plus-box-multiple</v-icon>
        {{ $t("Add Education") }}
      </v-btn>
    </v-col>

    <v-dialog v-model="educationDialog" width="500">
      <v-card class="rounded-lg">
        <v-btn @click="close" elevation="2" icon class="close-icon">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-text class="py-10">
          <v-row>
            <v-col cols="12">
              <v-text-field
                outlined
                hide-details
                dense
                v-model="itemForEdit.institution"
                :label="$t('Institution')"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                outlined
                hide-details
                class="text-arabic"
                dense
                v-model="itemForEdit.institutionAr"
                :label="$t('Institution') + ' ' + $t('Arabic')"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                outlined
                hide-details
                dense
                v-model="itemForEdit.degreeName"
                :label="$t('Degree Name')"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                outlined
                hide-details
                class="text-arabic"
                dense
                v-model="itemForEdit.degreeNameAr"
                :label="$t('Degree Name') + ' ' + $t('Arabic')"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                outlined
                hide-details
                dense
                v-model="itemForEdit.graduationYear"
                :label="$t('Graduation Year')"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="py-5 justify-center">
          <v-btn color="primary" outlined @click="close">
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn color="primary" @click="save">
            {{ $t("Save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="deleteConfirmationDialog"
    >
      <v-card class="pt-10 pb-5">
        <v-btn
          @click="deleteConfirmationDialog = false"
          elevation="2"
          icon
          class="close-icon pa-0"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-text>
          <h3 class="text-center">
            {{ $t("Are you sure you want to delete?") }}
          </h3>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            color="primary"
            outlined
            @click="deleteConfirmationDialog = false"
            >{{ $t("No") }}</v-btn
          >
          <v-btn color="primary" @click="deleteItem(deleteItemIndex)">{{
            $t("Yes")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
function defaultItem() {
  return {
    institution: "",
    institutionAr: "",
    degreeName: "",
    degreeNameAr: "",
    graduationYear: "",
  };
}

export default {
  name: "Education",
  props: {
    editable: {
      required: true,
    },
    therapistInfo: {
      required: true,
    },
  },
  data: () => ({
    educationDialog: false,
    deleteConfirmationDialog: false,
    deleteItemIndex: null,
    itemForEdit: defaultItem(),
  }),

  methods: {
    editItem(item, isNew = false) {
      this.itemForEdit = { ...defaultItem(), ...item };
      this.itemForEdit._isNew = isNew;
      this.itemForEdit._ref = item;

      this.educationDialog = true;
    },

    deleteConfirmation(index) {
      this.deleteConfirmationDialog = true;
      this.deleteItemIndex = index;
    },

    deleteItem(index) {
      this.therapistInfo.education.splice(index, 1);
      this.deleteConfirmationDialog = false;
    },

    addItem() {
      const item = defaultItem();
      this.editItem(item, true);
    },

    close() {
      this.educationDialog = false;
    },

    save() {
      if (this.itemForEdit._isNew) {
        this.therapistInfo.education.push(this.itemForEdit._ref);
      }

      for (let k of Object.keys(defaultItem())) {
        this.itemForEdit._ref[k] = this.itemForEdit[k];
      }

      this.close();
    },
  },
};
</script>
