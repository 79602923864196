<template>
  <v-dialog v-model="dialog" persistent width="700">
    <v-card class="rounded-lg">
      <v-btn @click="dialog = false" elevation="2" icon class="close-icon pa-0">
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <v-card-text class="pa-8">
        <v-form
          ref="changePasswordForm"
          v-model="valid"
          lazy-validation
          @submit.prevent
        >
          <v-row>
            <v-col cols="12" class="py-0">
              <v-text-field
                outlined
                v-model="currentPassword"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[required, min]"
                :type="show ? 'text' : 'password'"
                :label="$t('Old Password')"
                hint="At least 8 characters"
                counter
                autocomplete="on"
                @click:append="show = !show"
              />
            </v-col>

            <v-col cols="12" class="py-0">
              <v-text-field
                outlined
                v-model="password"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[required, min]"
                :type="show ? 'text' : 'password'"
                :label="$t('New Password')"
                hint="At least 8 characters"
                counter
                autocomplete="on"
                @click:append="show = !show"
              />
            </v-col>

            <v-col cols="12" class="py-0">
              <v-text-field
                outlined
                block
                v-model="verify"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[required, passwordMatch]"
                :type="show ? 'text' : 'password'"
                :label="$t('ConfirmPassword')"
                counter
                autocomplete="on"
                @click:append="show = !show"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="pb-6">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="px-8 me-3"
          large
          :disabled="!valid"
          @click="changePasswordAction"
        >
          {{ $t("Yes") }}
        </v-btn>
        <v-btn
          color="primary"
          outlined
          class="px-8"
          large
          @click="dialog = false"
        >
          {{ $t("No") }}
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations } from "vuex";

export default {
  name: "ChangePassword",

  data: () => {
    return {
      dialog: false,
      currentPassword: "",
      password: "",
      verify: "",
      valid: false,
      show: false,
    };
  },

  computed: {
    passwordMatch() {
      return () =>
        this.password === this.verify || this.$t("Passwords must match");
    },
    required() {
      return (value) => !!value || this.$t("Required:");
    },
    min() {
      return (v) => (v && v.length >= 8) || this.$t("Min 8 characters");
    },
  },

  methods: {
    ...mapActions("user", ["changePassword"]),
    ...mapMutations(["addNotification"]),

    async changePasswordAction() {
      if (this.$refs.changePasswordForm.validate()) {
        await this.changePassword({
          currentPassword: this.currentPassword,
          newPassword: this.password,
        });

        this.dialog = false;
        this.$emit("changed");
        this.addNotification({
          message: "Password successfully changed",
          icon: "mdi-check-outline",
          timeout: 3000,
        });
      }
    },
  },
};
</script>
