<template>
  <v-dialog v-model="dialog" persistent width="700">
    <v-card class="rounded-lg">
      <v-btn @click="dialog = false" elevation="2" icon class="close-icon pa-0">
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <v-card-text class="pa-8">
        <p class="text-center mb-0">
          {{ $t("Would you like to re-take the initial assessment?") }}
        </p>
      </v-card-text>

      <v-card-actions class="pb-6">
        <v-spacer></v-spacer>
        <v-btn color="primary" class="px-8 me-3" large @click="onRetake">
          {{ $t("Yes") }}
        </v-btn>
        <v-btn
          color="primary"
          outlined
          class="px-8"
          large
          :to="{ name: 'ChangeTherapist' }"
        >
          {{ $t("No") }}
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import UserMixin from "@/mixins/UserMixin";

export default {
  name: "ChangeTherapistDialog",

  mixins: [UserMixin],

  data: () => {
    return {
      dialog: false,
    };
  },

  methods: {
    onRetake() {
      if (this.me.therapyType === "Teen Therapy") {
        this.$router.push({
          name: "TeenTherapyReasonsRetake",
          query: { next: "change-therapist" },
        });
      } else {
        this.$router.push({
          name: "PrimaryReasonsRetake",
          query: { next: "change-therapist" },
        });
      }
    },
  },
};
</script>
