<template>
  <v-card
    class="rounded-lg d-flex flex-column card-with-animation fill-height pb-4"
    :class="isActive ? '' : 'pt-16'"
    :disabled="isActive"
    elevation="2"
  >
    <!-- <v-toolbar color="accent" elevation="0"></v-toolbar> -->
    <v-toolbar color="#6EC1E4" elevation="0" v-if="isActive">
      <v-toolbar-title class="text-center w-100 white--text">
        {{ $t("Current Plan") }}
      </v-toolbar-title>
    </v-toolbar>
    <v-card-title class="pt-0">
      <h2
        class="headline font-weight-medium primary--text text-center text--lighten-1 mt-8 mb-0 w-100"
      >
        {{ $t(planMapper[subscriptionKey].name) }}
      </h2>
    </v-card-title>

    <v-card-title class="py-0">
      <h5
        class="display-1 font-weight-bold text-center mb-5 w-100"
        :class="$vuetify.rtl ? 'headline' : 'display-1'"
      >
        <span v-if="!$vuetify.rtl">
          {{ $t("AED") }}
        </span>

        <template>
          <del>{{ subscriptionPriceSelected.metadata['del_price'] }}</del>
          {{ subscriptionPriceSelected.metadata['1_month_price'] }}
          <span v-if="$vuetify.rtl">
            {{ $t("AED") }}
          </span>
          <sub class="font-weight-light font-size-12 ms-2"
            >/ {{ $t("Month") }}</sub
          >
        </template>
      </h5>
    </v-card-title>

    <div class="px-8 text-center has-border-bottom">
      <h4 v-if="planMapper[subscriptionKey].value === 'Starter'">
        {{ $t('1 Month Subscription') }}
      </h4>
      <h4 v-else-if="planMapper[subscriptionKey].value === 'Premium'">
        {{ $t('2 Months Subscription') }}
      </h4>

      <br/>
    </div>


    <v-card-text class="px-8 mt-7 pb-0">
      <v-list class="mb-12">
        <v-list-item
          dense
          v-for="feature in planMapper[subscriptionKey].features"
          class="px-0 font-weight-medium"
          :key="feature.name"
        >
          <v-icon left class="me-2 warning--text">
            {{ !feature.included ? "mdi-circle-medium" : "mdi-check" }}</v-icon
          >
          <span>
            {{ $t(feature.name) }}
          </span>
          <span v-if="!feature.included" class="ms-1 primary--text">
            <i>{{ $t("(coming soon)") }}</i>
          </span>
        </v-list-item>
      </v-list>
      <p class="body-2 mt-1 text-center" v-if="planMapper[subscriptionKey].value === 'month_1'">
        {{ $t("You will be charged AED 1,450") }}
      </p>
      <p class="body-2 mt-1 text-center" v-else-if="planMapper[subscriptionKey].value === 'month_2'">
        {{ $t("You will be charged AED 2,700") }}
      </p>
    </v-card-text>

    <!-- <v-expansion-panels class="mt-auto" flat>
      <v-expansion-panel elevation="0">
        <v-expansion-panel-header
          class="primary--text d-flex flex-column align-center font-weight-medium justify-center"
          disable-icon-rotate
          style="font-size: 17px !important"
          >{{ $t("Additional Savings") }}
          <template v-slot:actions>
            <v-icon left class="mt-3" color="primary"
              >mdi-plus-circle-outline</v-icon
            >
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="">
          <v-card-subtitle
            v-if="planMapper[subscriptionKey].value === 'Starter'"
            class="body-2 ma-0 py-2 pa-0 font-weight-light dark--text text--lighten-1"
          >
            <p class="lh-1">
              <span class="font-weight-medium">{{
                $t("1 Month Subscription - AED 266 / Week (Save 5%)")
              }}</span>
            </p>
            <p class="lh-1">
              <span class="font-weight-medium">{{
                $t("3 Months Subscription - AED 252 / Week (Save 10%)")
              }}</span>
            </p>
            <p class="lh-1 font-weight-medium">
              {{
                $t(
                  "Additional live sessions can be added starting from AED 315"
                )
              }}
            </p>
          </v-card-subtitle>
          <v-card-subtitle
            v-if="planMapper[subscriptionKey].value === 'Premium'"
            class="body-2 ma-0 py-2 pa-0 font-weight-light dark--text text--lighten-1"
          >
            <p class="lh-1">
              <span class="font-weight-medium">{{
                $t("1 Month Subscription -  AED 428 / Week (Save 5%)")
              }}</span>
            </p>
            <p class="lh-1">
              <span class="font-weight-medium">{{
                $t("3 Month Subscription - AED 405 / Week (Save 10%)")
              }}</span>
            </p>
            <p class="lh-1 font-weight-medium">
              {{
                $t(
                  "Additional live sessions can be added starting from AED 315"
                )
              }}
            </p>
          </v-card-subtitle>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels> -->

    <v-card-actions class="pb-5 pt-0">
      <template>
        <v-btn
          v-if="!isActive"
          @click="switchPlan"
          color="accent"
          outlined
          elevation="0"
          class="mx-auto px-5"
        >
          {{ $t("Switch plan") }}
        </v-btn>
        <v-btn
          v-else
          @click="extendPlan"
          color="accent"
          outlined
          elevation="0"
          class="mx-auto px-5"
        >
          {{ $t("Extend Billing Period") }}
        </v-btn>
      </template>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import UserMixin from "@/mixins/UserMixin";

export default {
  name: "BillingCardV2",

  mixins: [UserMixin],

  props: {
    subscriptionProp: {
      required: true,
    },

    subscriptionKey: {
      required: true,
    },
  },

  data: () => {
    return {
      showVariants: false,
      subscriptionPriceRadio: null,

      subscriptionPropCopy: [],
    };
  },

  computed: {
    planMapper() {
      return {
        month_1: {
          name: this.$t("1 Month Subscription"),
          value: "month_1",
          features: [
            {
              name: this.$t("4 X 50min Live Sessions / month"),
              included: true,
            },
            {
              name: this.$t("20min Free Initial Consultation"),
              included: true,
            },
            {
              name: this.$t("Chat Messaging"),
              included: true,
            },
            {
              name: this.$t("Future-Self Journal"),
              included: true,
            },
          ],
        },

        month_2: {
          name: this.$t("2 Months Subscription"),
          value: "month_2",
          features: [
            {
              name: this.$t("8 X 50min Live Sessions every 2 months"),
              included: true,
            },
            {
              name: this.$t("20min Free Initial Consultation"),
              included: true,
            },
            {
              name: this.$t("Chat Messaging"),
              included: true,
            },
            {
              name: this.$t("Future-Self Journal"),
              included: true,
            },
            // {
            //   name: this.$t("Wellness Hub"),
            //   included: false,
            // },
            // {
            //   name: this.$t("Progress Tracker"),
            //   included: false,
            // },
          ],
        },
      };
    },

    subscriptionPriceSelected() {
      return this.subscriptionPropCopy[0];
    },

    price() {
      let subscr = this.subscriptionPropCopy[0];
      if (subscr) return subscr.unit_amount / 100;

      return "";
    },

    isActive() {
      return (
        this.subscription?.stripeSubscription?.plan?.product ===
        this.subscriptionPropCopy?.[0]?.product
      );
    },
  },

  filters: {
    priceFormat(value) {
      if (value > 1000) return (value / 1000).toString().replace(".", ",");
      return value;
    },
  },

  methods: {
    ...mapActions("subscription", ["subscribeAction"]),

    // async onSubscribe() {
    //     let therapistId = this.$route.params.therapistId;

    //     if (!therapistId) {
    //         therapistId = this.myTherapistId
    //     }

    //     let data = {
    //         therapistId: therapistId,
    //         packageName: this.planMapper[this.subscriptionKey].name,
    //         priceId: this.subscriptionPriceRadio
    //     }
    //     let subscribeActionRes = await this.subscribeAction(data);
    //     window.location.replace(subscribeActionRes.url);
    //     // window.open(subscribeActionRes.url, '_blank');
    // },

    async switchPlan() {
      let therapistId = this.myTherapistId;

      let data = {
        therapistId: therapistId,
        packageName: this.planMapper[this.subscriptionKey].value,
        priceId: this.subscriptionPriceRadio,
      };
      let subscribeActionRes = await this.subscribeAction(data);
      window.location.replace(subscribeActionRes.url);
    },

    async extendPlan() {
      let therapistId = this.myTherapistId;

      let data = {
        therapistId: therapistId,
        packageName: this.planMapper[this.subscriptionKey].name,
        priceId: this.subscriptionPriceRadio,
      };
      let subscribeActionRes = await this.subscribeAction(data);
      window.location.replace(subscribeActionRes.url);
    },
  },

  mounted() {
    this.subscriptionPropCopy = this.subscriptionProp;
    this.subscriptionPropCopy.sort((a, b) => {
      if (a.nickname < b.nickname) {
        return -1;
      }
      if (a.nickname > b.nickname) {
        return 1;
      }
      return 0;
    });

    this.subscriptionPropCopy = this.subscriptionPropCopy.filter(
      (e) => e.active
    );

    this.subscriptionPriceRadio = this.subscriptionPropCopy[0].id;

    // console.log(this.planMapper)
  },
};
</script>

<style scoped>
.text-right {
  text-align: right !important;
}
</style>
