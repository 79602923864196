<template>
  <v-container class="profile">
    <v-row>
      <v-col cols="12" md="6" xl="4" offset-xl="1" class="pe-lg-12">
        <div class="position-sticky top-0 pt-3">
          <ProfileAvatar class="mb-15" />
        </div>
      </v-col>

      <v-col cols="12" md="6" xl="4" offset-xl="1">
        <v-form v-model="formValid" ref="profileForm">
          <v-row class="align-items-center">
            <v-col cols="12" sm="auto">
              <v-layout justify-end v-if="!editable">
                <v-btn
                  color="primary"
                  outlined
                  class="mt-5"
                  :ripple="false"
                  @click="edit"
                >
                  <v-icon small left class="me-1">mdi-pencil</v-icon>
                  {{ $t("EditProfile") }}
                </v-btn>
              </v-layout>
              <v-layout v-else justify-end>
                <v-btn
                  color="primary"
                  class="mt-5"
                  :ripple="false"
                  @click="save"
                >
                  <v-icon small left class="me-1">mdi-check</v-icon>
                  {{ $t("Save") }}
                </v-btn>
                <v-btn
                  color="primary"
                  outlined
                  class="ms-4 mt-5"
                  elevation="0"
                  :ripple="false"
                  @click="cancel"
                >
                  <v-icon small left class="me-1">mdi-close</v-icon>
                  {{ $t("Cancel") }}
                </v-btn>
              </v-layout>
            </v-col>

            <template v-if="!isTherapist">
              <v-col cols="12" sm="auto" class="ms-sm-auto">
                <v-switch
                  :ripple="false"
                  :class="[{ 'd-none': !editable }, 'reversed-label']"
                  hide-details
                  v-model="profile.anonymous"
                >
                  <template v-slot:label>
                    <span class="me-5">{{ $t("RemainAnonymous") }}</span>
                  </template>
                </v-switch>
              </v-col>
            </template>
          </v-row>

          <v-row v-if="profile.anonymous && !isTherapist">
            <v-col cols="12">
              <v-text-field
                v-model="profile.username"
                type="text"
                :readonly="!editable"
                :label="$t('Username')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="6">
              <v-text-field
                v-model="profile.firstName"
                type="text"
                :readonly="!editable"
                :label="$t('FirstName')"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="profile.lastName"
                type="text"
                :readonly="!editable"
                :label="$t('LastName')"
              ></v-text-field>
            </v-col>

            <template v-if="isTherapist">
              <v-col cols="6">
                <v-text-field
                  v-model="profileAr.firstName"
                  class="text-arabic"
                  type="text"
                  :readonly="!editable"
                  :label="$t('FirstName') + ' ' + $t('Arabic')"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="profileAr.lastName"
                  class="text-arabic"
                  type="text"
                  :readonly="!editable"
                  :label="$t('LastName') + ' ' + $t('Arabic')"
                ></v-text-field>
              </v-col>
            </template>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                :label="$t('Password')"
                @click:append-outer="openChangePassword"
                value="12345678"
                :disabled="!editable"
                readonly
                type="password"
                append-outer-icon="mdi-pencil"
              ></v-text-field>
              <ChangePassword
                ref="ChangePasswordDialogRef"
                @changed="editable = false"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-menu
                ref="birthDateMenuRef"
                v-model="birthDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <!--                  <v-text-field :readonly="!editable" v-model="profile.birthDate" :rules="dobRules"-->
                  <v-text-field
                    :readonly="!editable"
                    v-model="profile.birthDate"
                    :label="$t('DateOfBirth')"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :locale="$vuetify.rtl ? 'ar' : 'en'"
                  v-if="editable"
                  no-title
                  v-model="profile.birthDate"
                  :show-current="false"
                  :active-picker.sync="dateActivePicker"
                  :max="maxBirthDate"
                  min="1950-01-01"
                  @change="saveBirthDate"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="profile.location.country"
                :label="$t('Location')"
                :rules="countryRules"
                :items="countries.map((item) => item)"
                :readonly="!editable"
                :item-text="getCountryItemName"
                item-value="name"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="profile.location.city"
                :label="$t('City')"
                :readonly="!editable"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-radio-group
                :readonly="!editable"
                :class="[{ 'pointer-events-none': !editable }]"
                v-model="profile.gender"
                row
              >
                <v-radio :label="$t('Male')" value="male"></v-radio>
                <v-radio :label="$t('Female')" value="female"></v-radio>
                <v-radio
                  v-if="!isTherapist"
                  :label="$t('PreferNotToSay')"
                  value="preferNotToSay"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row v-if="isTherapist">
            <v-col cols="12" class="d-flex align-center">
              <v-checkbox
                :class="[{ 'pointer-events-none': !editable }]"
                class="simple"
                :readonly="!editable"
                v-model="onHold"
                :label="$t('On Hold')"
              ></v-checkbox>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="px-0"
                    plain
                    :ripple="false"
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <i class="mdi mdi-information-outline mdi-24px"></i>
                  </v-btn>
                </template>
                <span>{{
                  $t(
                    "Set your status to 'on hold' and you will stop being matched to new clients"
                  )
                }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <TherapistProfile
            v-if="isTherapist"
            :therapistDisorders="therapistDisorders"
            :profile="profile"
            :profileAr="profileAr"
            :therapistInfo="therapistInfo"
            :editable.sync="editable"
            :country.sync="profile.location.country"
            @update:therapistDisorders="updateTherapistDisorders"
          />
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { countries } from "../../../constants/countries";

import { mapActions } from "vuex";
import TherapistProfile from "@/components/therapist/TherapistProfile";
import ProfileAvatar from "@/components/Common/ProfileAvatar";
import UserMixin from "@/mixins/UserMixin";
import ChangePassword from "@/components/ChangePassword/ChangePassword";

function defaultTherapistInfo() {
  return {
    phoneNumber: "",
    capacity: 0,
    categoryTitle: "",
    experienceYears: "",
    license: {
      licensingAuthority: "",
      issueDate: "",
      expirationDate: "",
    },
    education: [],
    languageSpeak: [],
    trainingCertification: {
      psychodynamic: false,
      cognitiveBehavioral: false,
      dialecticBehavioral: false,
      traumaBased: false,
      psychoanalysis: false,
      integrative: false,
    },
  };
}

function defaultProfile() {
  return {
    firstName: "",
    lastName: "",
    username: "",
    bio: "",
    birthDate: "",
    gender: "male",
    location: {
      country: "",
      city: "",
    },
    anonymous: false,
  };
}

function defaultProfileAr() {
  return {
    firstName: "",
    lastName: "",
    username: "",
    bio: "",
  };
}
// const moment = require("moment-timezone");

export default {
  name: "Profile",
  components: { TherapistProfile, ProfileAvatar, ChangePassword },
  mixins: [UserMixin],

  data: () => {
    return {
      formValid: true,
      show: false,
      editable: false,
      dateActivePicker: null,
      birthDateMenu: false,
      countries,
      // dobRules: [(v) => !!v || "DOB is required"],
      countryRules: [(v) => !!v || "Country is required"],
      onHold: false,
      profile: defaultProfile(),
      profileAr: defaultProfileAr(),
      therapistInfo: defaultTherapistInfo(),
      therapistDisorders: [],
    };
  },

  computed: {
    maxBirthDate() {
      let today = new Date();
      today.setFullYear(today.getFullYear() - 18);
      return today.toISOString().substring(0, 10);
    },
  },

  watch: {
    birthDateMenu(val) {
      val && setTimeout(() => (this.dateActivePicker = "YEAR"));
    },
  },
  methods: {
    ...mapActions("user", ["updateMe"]),

    getCountryItemName(country) {
      return this.$t(country.name);
    },

    openChangePassword() {
      this.$refs.ChangePasswordDialogRef.dialog = true;
    },

    cancel() {
      this.editable = !this.editable;
      this.setUserInfoToDefault();
    },

    edit() {
      this.editable = !this.editable;
    },

    async save() {
      if (this.$refs.profileForm.validate()) {
        let updateData = {};

        if (this.onHold !== this.me.onHold) {
          updateData.onHold = this.onHold;
        }

        let profileUpdateData = {};
        let profileArUpdateData = {};
        let therapistInfoUpdateData = {};

        for (let [k, v] of Object.entries(this.profile)) {
          if (k === "avatar") {
            continue;
          }
          if (typeof this.me?.profile?.[k] === "object") {
            for (let [k2, v2] of Object.entries(v)) {
              if (this.me?.profile?.[k][k2] !== v2) {
                profileUpdateData[k] = v;
                break;
              }
            }
          } else if (this.me?.profile?.[k] !== v) {
            profileUpdateData[k] = v;
          }
        }

        for (let [k, v] of Object.entries(this.profileAr)) {
          if (k === "avatar") {
            continue;
          }

          if (typeof this.me?.profileAr?.[k] === "object") {
            for (let [k2, v2] of Object.entries(v)) {
              if (this.me?.profileAr?.[k][k2] !== v2) {
                profileArUpdateData[k] = v;
                break;
              }
            }
          } else if (this?.me?.profileAr?.[k] !== v) {
            profileArUpdateData[k] = v;
          }
        }

        console.log("profileArUpdateData: ", profileArUpdateData);

        if (this.me.therapistInfo) {
          if (
            JSON.stringify(this.therapistInfo) !==
            JSON.stringify(this.me.therapistInfo)
          ) {
            therapistInfoUpdateData = this.therapistInfo;
          }
        } else {
          if (
            JSON.stringify(this.therapistInfo) !==
            JSON.stringify(defaultTherapistInfo())
          ) {
            therapistInfoUpdateData = this.therapistInfo;
          }
        }

        if (Object.keys(profileUpdateData).length) {
          updateData.profile = profileUpdateData;
        }

        if (Object.keys(profileArUpdateData).length) {
          updateData.profileAr = profileArUpdateData;
        }

        if (this.isTherapist) {
          updateData.therapistDisorders = this.therapistDisorders;

          if (Object.keys(therapistInfoUpdateData).length) {
            updateData.therapistInfo = therapistInfoUpdateData;
          }
        }

        await this.updateMe(updateData);

        this.editable = false;
      }
    },

    saveBirthDate(date) {
      this.$refs.birthDateMenuRef.save(date);
    },

    setUserInfoToDefault() {
      this.onHold = this.me.onHold;

      this.profile = this.me?.profile
        ? JSON.parse(JSON.stringify(this.me.profile))
        : defaultProfile();

      this.profileAr = this.me?.profileAr
        ? JSON.parse(JSON.stringify(this.me.profileAr))
        : defaultProfileAr();

      if (!this.profile?.location) {
        this.profile.location = defaultProfile().location;
      }

      if (this.isTherapist) {
        this.therapistInfo = this.me?.therapistInfo
          ? JSON.parse(JSON.stringify(this.me.therapistInfo))
          : defaultTherapistInfo();
      }

      this.therapistDisorders = this.me?.therapistDisorders;
    },

    updateTherapistDisorders(val, id) {
      if (val) {
        this.therapistDisorders.push(id);
      } else {
        let index = this.therapistDisorders.findIndex((e) => e === id);

        if (index !== -1) {
          this.therapistDisorders.splice(index, 1);
        }
      }
      console.log("updateTherapistDisorders - val: ", val);
      console.log("id: ", id);
    },
  },

  mounted() {
    this.awaitPromiseCallback({
      key: "getMe",
      cb: async () => {
        this.setUserInfoToDefault();

        // this.setGlobalLoading(false);
      },
    });
  },
  // beforeMount() {
  //     this.getTimeZone()
  //   }
};
</script>
