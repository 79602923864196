<template>
  <v-row>
    <v-col cols="12">
      <h4>{{ $t("Trainings and Certification") }}</h4>
    </v-col>
    <v-col cols="12" md="6" class="py-0">
      <v-checkbox
        :class="[{ 'pointer-events-none': !editable }]"
        :readonly="!editable"
        v-model="therapistInfo.trainingCertification.psychodynamic"
        :label="$t('Psychodynamic Therapy')"
      ></v-checkbox>
    </v-col>
    <v-col cols="12" md="6" class="py-0">
      <v-checkbox
        :class="[{ 'pointer-events-none': !editable }]"
        :readonly="!editable"
        v-model="therapistInfo.trainingCertification.cognitiveBehavioral"
        :label="$t('Cognitive Behavioral Therapy (CBT)')"
      ></v-checkbox>
    </v-col>
    <v-col cols="12" md="6" class="py-0">
      <v-checkbox
        :class="[{ 'pointer-events-none': !editable }]"
        :readonly="!editable"
        v-model="therapistInfo.trainingCertification.dialecticBehavioral"
        :label="$t('Dialectical Behavior Therapy (DBT)')"
      ></v-checkbox>
    </v-col>
    <v-col cols="12" md="6" class="py-0">
      <v-checkbox
        :class="[{ 'pointer-events-none': !editable }]"
        :readonly="!editable"
        v-model="therapistInfo.trainingCertification.traumaBased"
        :label="$t('Trauma Based Therapy')"
      ></v-checkbox>
    </v-col>
    <v-col cols="12" md="6" class="py-0">
      <v-checkbox
        :class="[{ 'pointer-events-none': !editable }]"
        :readonly="!editable"
        v-model="therapistInfo.trainingCertification.psychoanalysis"
        :label="$t('Psychoanalysis')"
      ></v-checkbox>
    </v-col>
    <v-col cols="12" md="6" class="py-0">
      <v-checkbox
        :class="[{ 'pointer-events-none': !editable }]"
        :readonly="!editable"
        v-model="therapistInfo.trainingCertification.integrative"
        :label="$t('Integrative Therapy')"
      ></v-checkbox>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "TrainingCertefication",
  props: {
    editable: {
      required: true,
    },
    therapistInfo: {
      required: true,
    },
  },
  data: () => ({}),
};
</script>
