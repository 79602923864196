<template>
  <v-row>
    <v-col cols="12">
      <v-combobox
        multiple
        v-model="therapistInfo.categoryTitle"
        :items="titles"
        :readonly="!editable"
        :label="$t('Title')"
        item-value="value"
        item-text="name"
      >
      </v-combobox>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "CategoryTitle",
  props: {
    editable: {
      required: true,
    },
    therapistInfo: {
      required: true,
    },
    rules: {
      required: true,
    },
  },

  computed: {
    titles() {
      return [
        {
          name: this.$t("Clinical Psychologist"),
          value: "Clinical Psychologist",
        },
        {
          name: this.$t("Child and Family Psychologist"),
          value: "Child and Family Psychologist",
        },
        {
          name: this.$t("Counseling Psychologist"),
          value: "Counseling Psychologist",
        },
        {
          name: this.$t("Developmental Psychologist"),
          value: "Developmental Psychologist",
        },
        {
          name: this.$t("Marriage and Family Therapist"),
          value: "Marriage and Family Therapist",
        },
      ];
    },
  },
};
</script>
