<template>
  <div>
    <MainBilling
      v-if="!changePlan"
      :changePlan.sync="changePlan"
      :history.sync="history"
    />
    <History v-show="history" />
    <v-container>
      <v-row class="pb-2" v-if="changePlan" align="center">
        <v-col cols="auto">
          <v-btn
            @click="toggleChangePlan"
            plain
            :ripple="false"
            color="accent"
            small
            outlined
            icon
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <h2 class="headline font-weight-medium">{{ $t("MyPlan") }}</h2>
        </v-col>
      </v-row>
      <v-row class="mt-5 justify-center">
        <template v-for="(subscription_, key) in subscriptions">
          <v-col v-if="key != 'basic'" cols="12" sm="6" md="4" :key="key">
            <BillingCard
              v-if="changePlan"
              :subscriptionProp="subscription_"
              :subscriptionKey="key"
            />
          </v-col>
        </template>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import UserMixin from "@/mixins/UserMixin";
import BillingCard from "@/components/Billing/BillingCard";
import MainBilling from "@/components/Billing/MainBilling";
import History from "@/components/Billing/History";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "MyPlan",
  mixins: [UserMixin],
  components: { BillingCard, MainBilling, History },

  data: () => {
    return {
      history: false,
      changePlan: false,
    };
  },

  computed: {
    ...mapGetters("subscription", ["subscriptions"]),
  },

  methods: {
    ...mapActions("subscription", ["getSubscriptions"]),

    toggleChangePlan() {
      this.changePlan = !this.changePlan;
    },
  },

  mounted() {
    this.awaitPromiseCallback({
      key: "getMe",
      cb: async () => {
        await this.getSubscriptions();
        // this.setGlobalLoading(false);
      },
    });
  },
};
</script>
