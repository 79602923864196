<template>
  <v-row>
    <v-col cols="12">
      <v-textarea
        counter
        persistent-hint
        :rules="bioRules"
        :readonly="!editable"
        height="150px"
        name="input-7-4"
        :label="$t('About Me')"
        v-model="profile.bio"
      ></v-textarea>
    </v-col>
    <v-col cols="12">
      <v-textarea
        counter
        :rules="bioRulesAr"
        class="text-arabic"
        :readonly="!editable"
        height="150px"
        name="input-7-4"
        label="About Me Arabic"
        v-model="profileAr.bio"
      ></v-textarea>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "About",
  props: {
    editable: {
      required: true,
    },

    profile: {
      required: true,
    },

    profileAr: {
      required: true,
    },
  },

  computed: {
    bioRules() {
      return [
        (v) => !!v || this.$t("This field is required"),
        (v) => (v && v.length >= 400) || this.$t("Minimum 400 characters"),
        (v) =>
          (v && v.length <= 1500) ||
          this.$t("This field exceeds maximum allowed characters"),
      ];
    },

    bioRulesAr() {
      return [
        (v) => {
          if (v.length > 1500) {
            return this.$t("This field exceeds maximum allowed characters");
          }

          return (
            v.length === 0 ||
            v.length >= 400 ||
            this.$t("Minimum 400 characters")
          );
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.v-textarea::v-deep .v-input__slot {
  height: auto !important;
}
</style>
