<template>
  <v-container
    :fluid="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? true : false"
  >
    <TherapistCards />
  </v-container>
</template>

<script>
import TherapistCards from "@/components/Client/TherapistCards";

export default {
  name: "ChangeTherapist",
  components: { TherapistCards },
};
</script>

<style scoped></style>
