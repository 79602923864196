<template>
  <v-container
    :fluid="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? true : false"
  >
    <ViewMyTherapist />
  </v-container>
</template>

<script>
import ViewMyTherapist from "@/components/Client/ViewMyTherapist";
import UserMixin from "@/mixins/UserMixin";

export default {
  name: "MyTherapist",
  components: { ViewMyTherapist },
  mixins: [UserMixin],
};
</script>
