<template>
  <v-card v-if="myTherapist" elevation="0">
    <v-row class="ma-0">
      <v-col cols="12" md="6" xl="5" class="pa-0">
        <v-card
          color="primary"
          class="fill-height border-mobile-0"
          :class="
            $vuetify.rtl ? 'border-left-radius-0' : 'border-right-radius-0'
          "
          rounded="lg"
          outlined
          elevation="0"
        >
          <div class="pa-lg-10 pa-3 d-flex flex-column">
            <div class="d-flex">
              <UserAvatar :user="myTherapist" :size="120" />
              <div class="ms-6 mt-2">
                <h2 class="white--text">
                  {{ getUserName(myTherapist) }}
                </h2>
                <p class="white--text font-weight-bold mt-3 mb-0">
                  {{ therapistCategoryTitle(myTherapist) }}
                </p>
                <p class="white--text">
                  {{ therapistExperienceYears(myTherapist) }}
                  {{ $t("years in practice") }}
                </p>
              </div>
            </div>

            <blockquote
              class="blockquote ps-2 py-15 border-left-0 align-self-center"
            >
              <img
                src="./../../assets/big-quote.png"
                alt="Quote icon"
                width="75"
              />
              <!-- <h3 class="font-weight-medium mt-2 mb-8 white--text">Get to know me</h3> -->
              <p class="body-2 mt-6 white--text text-pre-wrap text-break">
                {{ therapistBio(myTherapist) }}
              </p>
            </blockquote>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" xl="7" md="6" class="pa-0">
        <v-card
          rounded="lg"
          class="d-flex flex-column justify-space-between fill-height px-md-10 px-5"
          :class="
            $vuetify.rtl ? 'border-right-radius-0' : 'border-left-radius-0'
          "
          elevation="0"
          outlined
        >
          <div>
            <h3 class="mt-10 mb-1">{{ $t("Languages") }}</h3>
            <template v-if="therapistLanguageSpeak(myTherapist).length">
              <span
                v-for="(lang, i) in therapistLanguageSpeak(myTherapist)"
                :key="lang"
                class="me-1 opacity-50 subtitle-2"
              >
                {{ $t(lang) }}
                <template
                  v-if="i < therapistLanguageSpeak(myTherapist).length - 1"
                  >{{ ", " }}</template
                >
              </span>
            </template>
            <template v-else>
              <span class="m3-2 opacity-50">N/A</span>
            </template>
            <template v-if="therapistEducation(myTherapist)">
              <h3 class="mt-10">
                {{ $t("Education") }}
              </h3>
              <div
                v-for="(edu, i) in therapistEducation(myTherapist)"
                class="mt-3"
                :key="i + 'e'"
              >
                <!-- <v-list
                  color="transparent"
                  tag="ul"
                  class="pa-0"
                  style="column-count: 2"
                  dense
                >
                  <v-list-item tag="li" class="px-0">
                    <v-list-item-content>
                      <v-list-item-title><b>{{ $t("Degree Name") }}</b></v-list-item-title>
                      <v-list-item-subtitle>{{ therapistEducationDegreeName(edu) || "-" }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item tag="li" class="px-0">
                    <v-list-item-content>
                      <v-list-item-title><b>{{ $t("Institution") }}</b></v-list-item-title>
                      <v-list-item-subtitle>{{ therapistEducationInstitution(edu) || "-" }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list> -->
                <v-row>
                  <v-col cols="12" md="6">
                    <b>{{ $t("Degree Name") }}</b>
                    <p class="font-size-14 text-elipsis">
                      {{ edu.degreeName || "-" }}
                    </p>
                  </v-col>
                  <v-col cols="12" md="6">
                    <b>{{ $t("Institution") }}</b>
                    <p class="font-size-14 text-elipsis">
                      {{ edu.institution || "-" }}
                    </p>
                  </v-col>
                </v-row>
              </div>
            </template>

            <template v-if="therapistTrainingCertification(myTherapist)">
              <h3 class="mt-10">{{ $t("Trainings and Certification") }}</h3>
              <div class="mt-3">
                <!-- <v-list
                  color="transparent"
                  tag="ul"
                  class="pa-0"
                  style="column-count: 2"
                  dense
                >
                  <template v-for="(val, key, i) in therapistTrainingCertification(myTherapist)">
                    <v-list-item
                      v-if="val"
                      tag="li"
                      class="px-0"
                      :key="i + 'e'"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-icon
                            v-if="val"
                            size="18"
                            class="success--text me-2">mdi-check-decagram</v-icon>

                          <b class="me-1 font-weight-medium">{{ $t(trainingCertificationMapper[key]) }}</b>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list> -->
                <v-row>
                  <template
                    v-for="(val, key, i) in therapistTrainingCertification(
                      myTherapist
                    )"
                  >
                    <v-col
                      cols="12"
                      md="6"
                      v-if="val"
                      :key="i + 'e'"
                      class="d-flex"
                    >
                      <v-icon v-if="val" size="18" class="success--text me-2"
                        >mdi-check-decagram</v-icon
                      >

                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <b
                            v-bind="attrs"
                            v-on="on"
                            class="me-1 font-size-13 font-weight-medium text-elipsis"
                          >
                            {{ $t(trainingCertificationMapper[key]) }}</b
                          >
                        </template>
                      </v-tooltip>
                    </v-col>
                  </template>
                </v-row>
              </div>
            </template>

            <template v-if="currentTherapistSpeciality">
              <h3 class="mt-8">{{ $t("Speciality") }}</h3>
              <v-chip-group column class="mt-3">
                <v-chip
                  outlined
                  disabled
                  small
                  class="opacity-100 mb-3 font-weight-medium font-size-13"
                  v-for="(name, i) in currentTherapistSpeciality"
                  :key="i + 'e'"
                >
                  {{ $t(name) }}
                </v-chip>
              </v-chip-group>
            </template>
          </div>
          <v-btn
            @click="changeTherapist"
            color="secondary"
            class="py-md-3 mt-4 mb-8 align-self-end ms-auto"
          >
            <v-icon left>mdi-swap-horizontal</v-icon>
            {{ $t("Change Therapist") }}
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
    <ChangeTherapistDialog ref="ChangeTherapistDialog" />
  </v-card>
</template>

<script>
import UserAvatar from "@/components/UserAvatar";
import UserMixin from "@/mixins/UserMixin";
import ChangeTherapistDialog from "@/components/Client/ChangeTherapistDialog";
import { mapActions } from "vuex";
import TherapistMixin from "@/mixins/TherapistMixin";
import { therapyTypeToDisorderCategoryMapper } from "@/constants/therapyType";

export default {
  name: "ViewMyTherapist",
  components: { UserAvatar, ChangeTherapistDialog },
  mixins: [UserMixin, TherapistMixin],

  data: () => {
    return {
      trainingCertificationMapper: {
        traumaBased: "Trauma Based Therapy",
        dialecticBehavioral: "Dialectical Behavior Therapy (DBT)",
        cognitiveBehavioral: "Cognitive Behavioral Therapy (CBT)",
        psychodynamic: "Psychodynamic Therapy",
        psychoanalysis: "Psychoanalysis",
        integrative: "Integrative Therapy",
      },
    };
  },

  computed: {
    currentTherapistSpeciality() {
      return this.therapistSpeciality(
        this.myTherapist,
        therapyTypeToDisorderCategoryMapper[this.myTherapyType]
      );
    },
  },

  methods: {
    ...mapActions("disorder", {
      disorderGetAll: "getAll",
    }),

    changeTherapist() {
      this.$refs.ChangeTherapistDialog.dialog = true;
      // this.$router.push({name: 'ChangeTherapist'})
    },
  },

  mounted() {
    this.awaitPromiseCallback({
      key: "getMe",
      cb: () => {
        if (!this.disorders.length) {
          this.disorderGetAll().catch((err) =>
            console.error("Error on Disorder get", err)
          );
        }
      },
    });
  },
};
</script>
